// element matches support
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

// element closest polyfill
if (window.Element && !Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i,
            el = this;
        do {
            i = matches.length;
            while (--i >= 0 && matches.item(i) !== el) {
                // empty while
            }
        } while (i < 0 && (el = el.parentElement));
        return el;
    };
}

// CustomEvent polyfill
if (typeof window.CustomEvent !== 'function') {
    window.CustomEvent = function CustomEvent(event, params) {
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(
            event,
            params.bubbles || false,
            params.cancelable || false,
            params.detail,
        );
        return evt;
    };

    CustomEvent.prototype = window.Event.prototype;
}

(function () {
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame =
            window[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame =
            window[vendors[x] + 'CancelAnimationFrame'] ||
            window[vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function (callback) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function () {
                callback(currTime + timeToCall);
            }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function (id) {
            clearTimeout(id);
        };
})();

if (!window.CSS || !CSS.supports || !CSS.supports('(--foo:red)')) {
    import(
        /* webpackChunkName: "css-vars-ponyfill" */
        'css-vars-ponyfill'
    )
        .then(({ default: cssVars }) => {
            cssVars();
        })
        .catch((err) => console.warn(err));
}

/* ie 11 grid fixes */
/*
if (/Trident\/7\.0/.test(navigator.userAgent)) {
    console.log('hello ie11');
    (function() {
        document.querySelectorAll('.articles').forEach(gridElm => {
            gridElm.children.forEach((elm, i) => {
                elm.style.msGridRow = i;
            });
        });
    })();
    console.log('weee');
}
*/
