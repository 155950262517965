export default function throttle(callback) {
    let requestId = null;
    let lastArgs;

    function later(context) {
        return function () {
            requestId = null;
            callback.apply(context, lastArgs);
        };
    }

    function throttled() {
        lastArgs = Array.from(arguments);

        if (requestId === null) {
            requestId = requestAnimationFrame(later(this));
        }
    }

    throttled.cancel = function () {
        cancelAnimationFrame(requestId);
        requestId = null;
    };

    return throttled;
}
